import React from "react";

import { graphql } from "gatsby";

import Elements from "../components/shared/Elements";
import Page from "../components/shared/Page";

const OtherPagesPage = ({
    data: {
        page: { elements = [], disabled },
    },
}) => {
    const cals = elements.filter((ele) => ele.type === "calculatorWithOutput");

    return (
        <Page>
            <Elements {...{ elements }} />
        </Page>
    );
};
export default OtherPagesPage;

export const query = graphql`
    query ($id: String) {
        page: otherPagesYaml(id: { eq: $id }) {
            ...Page
            disabled
            elements {
                ...EntireElement
            }
        }
    }
`;
